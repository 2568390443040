<template>
    <content-loader :height="300" secondaryColor="#ccd8ff" primaryColor="#e7ecff">
        <rect x="55" y="20" rx="5" ry="5" width="260" height="20" />
        <rect x="55" y="50" rx="5" ry="5" width="260" height="20" />
        <rect x="55" y="80" rx="5" ry="5" width="260" height="20" />
        <rect x="55" y="110" rx="5" ry="5" width="260" height="20" />
        <rect x="55" y="140" rx="5" ry="5" width="260" height="20" />
        <rect x="55" y="170" rx="5" ry="5" width="100" height="45" />
        <rect x="200" y="170" rx="5" ry="5" width="100" height="45" />
    </content-loader>
</template>

<script>
    import { ContentLoader } from 'vue-content-loader';
    export default {
        components : {
            ContentLoader
        }
    }
</script>
